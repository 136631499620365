
@import './media_queries.scss';

// FONTS
$font_regular: 'Rubik', sans-serif;
$font_title: 'Rubik', sans-serif;

// Levels
// Level 1 is the body background, each level is a tone increment. Between the levels we can have slighly darker colors to have hoover colors.
$color_level_1: #F6F6F6;
$color_level_1_hover: #f2f2f2;
$color_level_1_border: #d6d6d6;
$color_level_2: #c9c9c9;
$color_level_3: #c9c9c9;

// Font Awesome Icons, use on white backgrounds.
$color_font_awesome_icon_white_bg: #838383;

// Light colored buttons to be used on white backgrounds
$color_light_button_white: #34435b12;
$color_light_button_white_darker: rgb(52 67 91 / 11%);
$color_light_button_white_hover: #34435b1f;

$font_input_text: $font_regular;
$font_size_text: 16px;
$font_color: rgb(92,92,92);

// table color
$color_table_gray: #F0F0F0;

// Notice color
$color_notice_background: #f6f6f6;

// Dark grey border radius
$color_dark_border_radius: #A9A9A9;

// COLORS
$color_main_dark_blue: #002f43;;
$color_main_dark_blue_inactive: #858585;
$color_main_dark_blue_hover: rgb(65 80 104);
$color_main_dark_blue_hover_lighter: #004D6D;
$color_main_dark_blue_disabled: #34435b70;
$color_blue_hover_link: #2068be;

$color_main_light_blue: #478CE1;
$color_main_super_light_blue: #def2ff;
$color_main: black;
$color_body_bg: $color_level_1;
$color_titles: black;

$color_border_gray: #e5e5e5;
$color_info_box: #49a5e038;

$color_main_dark_gray: #343434;
$color_main_dark_gray_hover: #545454;

$color_border_white_bg_input: #e5e5e5;
$color_border_main_page: #b4b4b4;
$color_border_input: #E6E6E6;
$color_border_gray_background: #c1c1c1;
$color_border_dark_background: #646464;
$color_border_light_background: #dddddd;
$color_border_light_background_middle: #b0b0b0; // Detail views border separator
$color_border_hover_list_item: #f8f8f8;
$color_border_validate_error: #c62f2f;
$color_border_boxes: #a1a1a1;

// Used for border-buttons with white background. On hoover the bg and color changes.
$color_border_white_bg_buttons: #cfcfcf;

// SELECTORS
$color_border_divider: #b4b4b4; // Used on 
$color_border_divider_white: #e5e5e5; // Used on white backgrounds

// BUTTONS
$color_fine_button_bg: $color_main;
$color_fine_button_text: white;
$color_hashtag_sku: #34435bbd;

$color_gray_button_bg: #eeeeee;
$color_gray_button_bg_hover: #dfdfdf;

$color_green_button_bg: #0CEB80;

// OTHER
$color_main_dark_gray_light: #80808038;
$color_hover_white_background: #d9d9d9;

// FONT AWESOME
$color_fa_icon_light_gray: #c5c5c5;

// H1 H2 H3 H4 H5 H6 H7
$font_size_h1: 24px;
$font_size_h2: 20px;
$font_size_h3: 18px;
$font_size_h4: 16px;
$font_size_h5: 14px;
$font_size_h6: 12px;

$color_h1: black;
$color_h2: #2d2d2d;

$color_green_statistics_dark: #24bd42;
$color_green_statistics_light: #0edd0e;

$color_green_yes: #0BBFA0;
$color_red_no: red;
$color_red_no_light: red;

$color_red: rgb(226, 67, 67);
$color_green: #29d429;
$color_yellow: #d6d70c;

// BATCH
$color_batch_green: #0da800;

$color_button_border: #dddddd;
$color_button_border_hover: #898989;

// BUTTON COLORS
$color_button_body_bg_darker_1: #e3e3e3;
$color_button_body_bg_darker_2: #d1d1d1;

// BACKGROUNDS
// Active Filters, Boxes, StatCounters etc
$color_bg_light_box: #f2f2f2;

// SIDEBAR MENU - GLOBAL
$sidebar_menu_width: 90px;

// GLOBAL VALUES
$border_radius_global: 0px;

// Dimentions
$width_body: 1500px;

@mixin button() {
    text-transform: uppercase;
    font-weight: bold;
    padding: 0px 20px;
    font-family: $font_title;
    white-space: nowrap;
    font-size: 12px;
    color: white;
    background-color: $color_main_dark_blue;
    user-select: none;
    border-radius: $border_radius_global;
    cursor: pointer;
    transition: all 0.2s;
    height: 36px;
    display: flex;
    gap: 7px;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    
    &:hover {
        transition: all 0.2s;
        background-color: $color_main_dark_blue_hover_lighter;
    }

    .button-text {
        padding: 0px 10px;
    }

    .icon {
        font-size: 16px;
        height: 15px;
        width: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@mixin sitewide_core() {
    @content;
}

@include sitewide_core() {
    
    html {
        margin: 0;
        padding: 0;
    }
    
    body {
        background-color: $color_body_bg;
        padding: 0px;
        margin: 0px;
        color: $font-color;
        font-family: $font_regular;
        min-height: 100vh;
        margin: 0 !important;
        color: $color_main;
    
        &::-webkit-scrollbar {
            display: none;
        }
    }

    body.lock-body {
        overflow: hidden;
    }
    
    input {
        font-family: $font_title;
    }

    input[type="checkbox"] {
        height: 16px;
        width: 16px;
    }

    // When using Firefox arorws show up in the field, we want to hide these
    input[type='number'] {
        appearance: textfield;
        -moz-appearance: textfield;
    }
    
    button, input[type="submit"], input[type="reset"] {
        cursor: pointer;
        outline: none;
        border:none;
        padding: 10px 20px;
        background-color: white;
        color: black;
        font-size: 16px;
    }
    
    a {
        color: $color_main_dark_blue;
        text-decoration: none;
    }
    
    input {
        font-style: $font_title;
        color: $font-color;
        font-size: 16px;
        font-weight: 400;
        padding: 10px;
        border-radius: $border_radius_global;
        border: 0px;
        box-sizing: border-box;
        border: solid $color_body_bg 1px;
        outline: none;
    }
    
    button {
        font-family: $font_title;
    }
    
    h1, h2, h3, h4, h5 {
        font-family: $font_title;
        font-weight: initial;
        margin:0;
        padding:0;
        font-weight: 600;
    }

    h1 { font-size: 30px; }
    h2 { font-size: 26px; }
    h3 { font-size: 20px; }
    
    textarea {
        padding:10px;
        font-size: $font_size_text;
        border: solid $color_body_bg 3px;
        border-radius: $border_radius_global;
        font-family: $font_title !important;
        color: $font-color;

        &::-webkit-scrollbar {
            display: none;
        }
    }
    
    .module-design {
        background: #e7e8e9;
        padding: 20px;
        margin-top:20px;
    }
    
    .flex1 {
        flex: 1;
    }

    select {
        padding: 10px;
        border: 0px;
        padding: 10px;
    }

    p {
        padding: 0px;
        margin: 0px 0px 10px 0px;
    }

    ul {
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .button {
        @include button();
    }

}

@mixin sitewide() {
    @content;
}

@include sitewide() {

    .page-header-flex {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-content: center;
    
        .page-header-right {

            .fine-button-page-header {
                margin-right: 20px;
            }
            
        }

    }

    .info-box {
        padding: 20px;
        background: white;

        &:last-child p {
            padding-bottom: 0px;
            margin-bottom: 0px;
        }
    }

    .small-gray-button {

        font-family: $font_title;
        border-radius: $border_radius_global;
        background-color: $color_level_1;
        text-transform: uppercase;
        font-weight: bold;
        padding: 0px 10px;
        white-space: nowrap;
        font-size: 12px;
        -webkit-user-select: none;
        user-select: none;
        cursor: pointer;
        transition: all 0.2s;
        height: 25px;
        display: flex;
        color: #474747;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        
        &:hover {
            transition: all 0.2s;
            background-color: $color_level_1_hover;
        }
    
        .icon {
            font-size: 16px;
            height: 15px;
            width: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    
    }

    .button-green {
        background-color: $color_green_button_bg;
        text-transform: uppercase;
        font-weight: 700;
        border-radius: $border_radius_global;
    }

    .button-light {
        text-transform: uppercase;
        font-weight: bold;
        padding: 0px 20px;
        font-family: $font_title;
        white-space: nowrap;
        font-size: 12px;
        border: 1px solid $color_level_1;
        background-color: white;
        user-select: none;
        border-radius: $border_radius_global;
        cursor: pointer;
        transition: all 0.2s;
        height: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        
        &:hover {
            transition: all 0.2s;
            color: white;
            border: 1px solid transparent;
            background-color: $color_main_dark_blue;
        }

        .button-text {
            padding: 0px 10px;
        }

        .icon {
            font-size: 16px;
            height: 15px;
            width: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .button-short {
        height: 26px;
        text-transform: uppercase;
        font-weight: bold;
        padding: 4px 20px;
        font-family: $font_title;
        white-space: nowrap;
        font-size: 12px;
        color: white;
        background-color: $color_main_dark_blue;
        user-select: none;
        border-radius: $border_radius_global;
        cursor: pointer;
        transition: all 0.2s;
        display: flex;
        gap: 7px;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
    
        &:hover {
            transition: all 0.2s;
            background-color: $color_main_dark_blue_hover_lighter;
        }
    }
}

@mixin include_fonts() {
    @content;
}

@include include_fonts() {

    // @font-face {
    //     font-family:'Biennale Black';
    //     src: url('../fonts/biennale/black/black.eot');
    //     src: url('../fonts/biennale/black/black.eot?#iefix') format('embedded-opentype'),
    //         url('../fonts/biennale/black/black.woff2') format('woff2'),
    //         url('../fonts/biennale/black/black.woff') format('woff'),
    //         url('../fonts/biennale/black/black.svg#black') format('svg');
    //     font-weight: 400;
    //     font-style: normal;
    //     font-stretch: normal;
    //     unicode-range: U+0020-00FE;
    // }

    // @font-face {
    //     font-family:'Biennale Regular';
    //     src: url('../fonts/biennale/regular/regular.eot');
    //     src: url('../fonts/biennale/regular/regular.eot?#iefix') format('embedded-opentype'),
    //         url('../fonts/biennale/regular/regular.woff2') format('woff2'),
    //         url('../fonts/biennale/regular/regular.woff') format('woff'),
    //         url('../fonts/biennale/regular/regular.ttf') format('ttf'),
    //         url('../fonts/biennale/regular/regular.otf') format('otf'),
    //         url('../fonts/biennale/regular/regular.svg#black') format('svg');
    //     font-weight: 400;
    //     font-style: normal;
    //     font-stretch: normal;
    //     unicode-range: U+0020-00FE;
    // }

    // @font-face {
    //     font-family:'HelveticaNowDisplay';
    //     src: url('../fonts/helvetica-now/display/black/black.eot');
    //     src: url('../fonts/helvetica-now/display/black/black.eot?#iefix') format('embedded-opentype'),
    //         url('../fonts/helvetica-now/display/black/black.woff2') format('woff2'),
    //         url('../fonts/helvetica-now/display/black/black.woff') format('woff'),
    //         url('../fonts/helvetica-now/display/black/black.svg#black') format('svg');
    //     font-weight: 400;
    //     font-style: normal;
    //     font-stretch: normal;
    //     unicode-range: U+0020-00FE;
    // }

    // @font-face {
    //     font-family:'HelveticaNowText';
    //     src: url('../fonts/helvetica-now/text/regular/regular.eot');
    //     src: url('../fonts/helvetica-now/text/regular/regular.eot?#iefix') format('embedded-opentype'),
    //         url('../fonts/helvetica-now/text/regular/regular.woff2') format('woff2'),
    //         url('../fonts/helvetica-now/text/regular/regular.woff') format('woff'),
    //         url('../fonts/helvetica-now/text/regular/regular.svg#regular') format('svg');
    //     font-weight: 400;
    //     font-style: normal;
    //     font-stretch: normal;
    //     unicode-range: U+0020-00FE;
    // }

    // @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600&display=swap');
    @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Quicksand:wght@300;400;500;600;700&family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
}

@mixin status_message_error() {
    padding: 10px;
    border-left: 7px solid $color_red_no;
    background-color: white;
}

@mixin status_message_success() {
    padding: 10px;
    border-left: 7px solid $color_green_yes;
    background-color: white;
}

@mixin page_header() {
    @content;
}

@include page_header() {

    .page-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .page-header-icon {
        font-size: 16px;
        height: 15px;
        width: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .page-header-button-text {
        padding: 0px 10px;
    }

    .page-header-title {
        font-size: 30px;
        font-family: $font_title;
        color: $color_h1;
        font-weight: 600;
    }

    .page-header-button {
        @include button();
        
        &:hover {
            .td-header-button-hover {
                transition: all 0.2s;
                border-bottom: 2px solid white;
            }
        }
    }
    
    .page-header-button-hover {
        transition: all 0.2s;
    }

    .icon-button {
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        background-color: #34435b;
        -webkit-user-select: none;
        user-select: none;
        border-radius: $border_radius_global;
        cursor: pointer;
        transition: all 0.2s;
        
        &:hover {
            transition: all 0.2s;
            background-color: $color_main_dark_blue_hover;
        }
    }

}

@mixin scrollbar_styling() {
    
    &::-webkit-scrollbar {
        width: 10px; /* Mostly for vertical scrollbars */
        height: 10px; /* Mostly for horizontal scrollbars */
    }
    
    &::-webkit-scrollbar-track { /* Background */
        background: $color_level_1;
    }

    &::-webkit-scrollbar-thumb { /* Foreground */
        background: $color_level_2;
    }

    &::-webkit-scrollbar-corner {
        background: transparent;
    }

}

/* Hide Number Arrows from Input */
@mixin hide_arrows_number_input() {
    input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
      
    /* Use the default styles for number input fields in other browsers */
    input[type="number"] {
        appearance: textfield;
        -moz-appearance: textfield; /* Firefox */
    }
}

/* Product Detail */

.NgxEditor {
    border: none !important;
    height: 100%;
}

.pd-product-description-editor-textarea{
    .NgxEditor__Content {
        height: 100%;
    }
}

.flex-1 {
    flex: 1;
}

// Tooltips
.tt-wrap {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.tt-body {
    padding: 10px;

    .tt-circle {
        font-size: 10px;
        padding-right: 10px;
        color: $color_main_dark_blue;
    }
}

.tt-row {
    padding: 5px 20px;
    transition: 0.2s all;
    user-select: none;
    display: flex;
    border-radius: $border_radius_global;
    align-items: center;
    cursor: pointer;

    &:hover {
        transition: 0.2s all;
        background-color: #eaeaea;
    }

    .icon {
        font-size: 10px;
        padding-right: 10px;
        color: $color_main_dark_blue;
    }
}

.tt-row-text {
    white-space: nowrap;
}

// KEEN SLIDER
.keen-slider:not([data-keen-slider-disabled]) {
    align-content: flex-start;
    display: flex;
    overflow: hidden;
    position: relative;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
    width: 100%;
  
    .keen-slider__slide {
      position: relative;
      overflow: hidden;
      width: 100%;
      min-height: 100%;
    }
  
    &[data-keen-slider-reverse] {
      flex-direction: row-reverse;
    }
  
    &[data-keen-slider-v] {
      flex-wrap: wrap;
    }
  }
  
.uppertitle {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 0.5px;
}

.ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

// POPUPS
@mixin pop_header() {

    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08), 0px 8px 12px rgba(0, 0, 0, 0.04);

    .pop-ghost {
        width: 80px;
    }

    .pop-title {
        font-size: 18px;
        font-weight: 600;
    }

    .pop-close {
        width: 40px;
        height: 40px;
        margin-right: 6px;

        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.2s all;
        cursor: pointer;
        border-radius: $border_radius_global;

        &:hover {
            background-color: white;
            transition: 0.2s all;
        }
    }
}